import { Banner1Section, BannerBlackFridaySection } from 'holded/modules/cms/domain/components/banner';
import { Page } from 'holded/modules/cms/domain/page';

type TopBarSection = {
  id: number;
  __component: string;
  pageData: Page;
} & Banner1Section &
  BannerBlackFridaySection;

const ERROR_MESSAGE =
  '¡Lo sentimos! Nuestra plataforma está experimentando una interrupción inesperada. Estamos trabajando para resolverlo lo antes posible.';

const isWarningVisible = process.env.NEXT_PUBLIC_SHOW_ERROR_WARNING === 'true';

export { ERROR_MESSAGE, isWarningVisible };

export type { TopBarSection };
