import dynamic from 'next/dynamic';
import { FC } from 'react';

import { hasKey } from 'holded/lib/utils';
import { DynamicSectionType } from 'holded/models/DynamicTypes';
import { ERROR_MESSAGE, isWarningVisible, TopBarSection } from 'holded/modules/cms/domain/components/topbar';
import { HeaderSection, Page } from 'holded/modules/cms/domain/page';
import { ErrorBanner } from 'holded/shared/ui/components/ErrorBanner';

type PropsStrapi = {
  header: HeaderSection;
  pageData: Page;
};

type PropsSection = {
  sectionData: TopBarSection;
  pageData: Page;
};

const sectionComponents: Pick<DynamicSectionType, 'banner.banner1' | 'banner.banner-bf'> = {
  'banner.banner1': dynamic(() => import('holded/modules/cms/ui/sections/banner/Banner1')) as FC,
  'banner.banner-bf': dynamic(() => import('holded/modules/cms/ui/sections/banner/BannerBlackFriday')) as FC,
};

const Section = ({ sectionData, pageData }: PropsSection) => {
  const SectionComponent = hasKey(sectionComponents, sectionData?.__component as keyof typeof sectionComponents)
    ? sectionComponents[sectionData.__component as keyof typeof sectionComponents]
    : null;

  if (!SectionComponent) {
    return null;
  }

  return <SectionComponent {...{ ...sectionData, ...{ pageData } }} />;
};

const TopBar = ({ header, pageData }: PropsStrapi) => (
  <>
    {header?.attributes?.topBar.map((section) => (
      <Section key={`${section.__component}${section.id}`} sectionData={section} pageData={pageData} />
    ))}
    {isWarningVisible && <ErrorBanner message={ERROR_MESSAGE} />}
  </>
);

export default TopBar;
