type ErrorBannerProps = {
  message: string;
};

const ErrorBanner = ({ message }: ErrorBannerProps) => (
  <div className="relative top-0 z-10 w-full bg-red-600">
    <div className="max-w-navbar mx-auto px-1.5 py-2.5 flex flex-row lg:flex-col justify-center">
      <div className="flex flex-col lg:flex-row items-center md:items-center justify-center md:justify-between gap-1.5 lg:gap-5 sm:text-center sm:px-0">
        <p className="text-b3 leading-6 text-white flex">{message}</p>
      </div>
    </div>
  </div>
);

export { ErrorBanner };
